.ecoTemplateCard {
    position: relative;
    max-height: 152px;
    height: 152px;
    margin-right: 8px;
    box-sizing: border-box;
    user-select: none;
    box-shadow: 0px 3px 6px #D2E1ED;
    border-radius: 4px;
    overflow: hidden;
    .categoryImg {
        width: 35px;
    }
}