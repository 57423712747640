.ecoActionShareContainer {
    width: 381px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 48px 24px 48px 24px;
    border-width: 1px;
    border-color: #E3F2FF;
    box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);

    .ecoActionShareCard {
        border-width: 1px;
        border-color: #E3F2FF;
        border-radius: 8px;
        padding: 10px;
        box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);

    }

    .ecoShareLogo {
        width: 50px;
        height: auto;
        object-fit: contain;
    }

    .logoShareImg {
        padding: 12px 0px 8px 0px;
        width: 50px;
        height: 30px;
    }

    .shareImage {
        width: 100%;
        height: 182px;
    }

    .divBgImage {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
    }
}