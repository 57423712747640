.pereferenceMenu {
    .MuiList-root-MuiMenu-list {
        padding-top: 0;
    }
    .MuiCheckbox-root {
        .MuiSvgIcon-root {
            transform: scale(0.889);
        }
    }
    .MuiFormControlLabel-label {
        font-size: 12px;
        color: #474747;
    }
    .menu-header {
        height: 48px;
        background-color: #DDF1FF;
    }
    .apply-btn {
        background-color: #8AC865;
        border-radius: 20px;
        height: 24px;
        width: 70px;
    }
}