.congratsContainer {
    position: relative;
    width: 381px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 48px 24px 48px 24px;
    border-width: 1px;
    border-color: #E3F2FF;
    box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);

    .congratsCon {
        position: absolute;
        bottom: 50px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .congratsSubCon {
        width: 100%;
        padding: 50px 16px;
    }

    .congratsBtnCon {
        padding: 0px 24px;
    }

    .animContainer {
        width: 100%;
        position: relative;
        animation: animateDiv 3s 1;
        animation-direction: normal;
        margin-bottom: 100px;
    }

    @keyframes animateDiv {
        0% {
            bottom: 0;
            top: 150px;
        }

        100% {
            bottom: 150px;
            top: 0px;
        }
    }
}