.Analytics {
    overflow-y: scroll;
    height: calc(100vh - 56px);
    background: #F7F7F7;
    overflow-x: hidden;

    .horizontalScroll {
        display: flex;
        overflow: hidden;
        column-gap: 40px;
    }

    .innerDivPadding {
        padding: 30px 16px 16px 16px;
        background-color: #F7F7F7;
        height: calc(100vh - 50px);
    }

    .chartSection {
        margin-top: 40px;
        background: #FFF;
        height: 250px;
        padding: 22px 16px 16px 16px;
        border: 1px solid #49607326;
    }

    .CampaignSection {
        margin-top: 32px;
    }

    @media only screen and (max-width: 1000px) {

        height: auto;
        overflow-x: unset;
        overflow-y: unset;

        .innerDivPadding {
            height: auto;
            padding: 5px 16px 16px 16px;
        }

        .chartSection {
            margin-top: 14px;
            background: transparent;
            height: auto;
            padding: 0;
            border: none;
        }


        .mobileBar {
            border-radius: 4px;
            margin-top: 10px;
            background: #FFF;
        }

        .CampaignSection {
            margin-top: 25px;
        }
    }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}