.userManagementContainer {

    .tablecellonHover {
        position: relative;

        .checkboxonHover {
            visibility: hidden;
        }

        .indexStyle {
            visibility: visible;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 48px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // .tablecellonHover:hover {
    //     .checkboxonHover {
    //         visibility: visible;
    //     }

    //     .indexStyle {
    //         visibility: hidden;
    //     }
    // }

    .indexChecked {
        display: none;
    }

    .statusCircle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    .tableRowHover {
        .showOnHoverAction {
            visibility: hidden;
            position: absolute;
            right: 0;
            top: 0;
            height: 47px;
            // background: #F2F9FF;
            background: #e2f1fe;
            border-radius: 4px 0px 0px 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-content: space-around;
            min-width: 144px;

            .MuiIconButton-root {
                padding: 5px 5px !important;
            }
        }
    }

    .tableRowHover:hover {
        background-color: #3787C912;

        .showOnHoverAction {
            visibility: visible;
        }
    }

    .hoverIconButton:hover {
        // background-color: rgb(138, 183, 86, 0.5);
    }

    .noData {
        background: #FFF;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .selectedStyle::before {
        content: "";
        background: #106AA5;
        position: absolute;
        left: 0;
        height: 100%;
        width: 4px;
        top: 0;
    }


    .mobileAddStyle {
        width: 56px;
        height: 56px;
        background: #8AC865 !important;
        color: #FFF;
        box-shadow: 0px 3px 6px #00000029 !important;
        position: fixed !important;
        bottom: 24px;
        right: 24px;
    }

}

.deleteIconContainer {
    width: 32px;
    height: 32px;
    background: rgb(230, 35, 35, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: 5px;
}

//Mobile user Card
.userCard {
    // background-color: #FFF;
    border-radius: 4px;
    box-shadow: 0px 1px 2px #00000029;
    min-height: 120px;
    padding: 16px 12px 16px 16px;
    margin-top: 16px;
}

.stackBoxBottom {
    position: fixed !important;
    bottom: 24px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;

    .innerBoxBottom {
        width: 168px;
        height: 56px;
        border-radius: 28px;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%) !important;
        background-color: #8AC865 !important;

        .innerButton {
            width: 56px;
        }
    }
}

.headerMobile {
    padding: 8px 0;
    position: sticky;
    top: 57px;
    z-index: 1;
    background: #F7F7F7;
}


@media only screen and (min-width: 1300px) {
    .userManagementContainer {
        .tableRowHover {
            .showOnHoverAction {
                width: 101%;
            }
        }
    }
}