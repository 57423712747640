.campaignStatusButton {
    width: 88px;
    height: 24px;
    border-radius: 16px !important;
    text-transform: capitalize;
    box-sizing: border-box;
    &.mobile {
        width: 128px;
        height: 40px;
        border-radius: 20px !important;
        font-size: 14px;
    }
    &.draft {
        border: 1px solid #f5865e;
        color: #f5865e;
        &::after {
            content: "Draft!";
        }
        &:hover {
            border: #4960734d;
            background-color: #4960734d;
            color: #000;
            text-transform: uppercase;
            font-weight: 500;
            &::after {
                content: "Edit";
            }
        }
    }
    &.otherStatus {
        border: 1px solid #496073;
        color: #000000;
        &.scheduled {
            &::after {
                content: "Scheduled";
            }
            &:hover {
                &::after {
                    content: "CANCEL";
                }
            }
        }
        &.running {
            &::after {
                content: "Running";
            }
            &:hover {
                &::after {
                    content: "PAUSE";
                }
            }
        }
        &.paused {
            &::after {
                content: "Paused";
            }
            &:hover {
                &::after {
                    content: "START";
                }
            }
        }
        &:hover {
            border: #496073;
            background-color: #496073;
            color: #f7f7f7;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}