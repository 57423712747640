.drop-zone-container {
    height: 160px;
    position: relative;
    box-sizing: border-box;
    .cancelButton {
        position: absolute;
        right: 16px;
        top: 16px;
    }
    .imgContainer {
        height: 160px;
    }
    .dropZone {
        width: 100%;
        height: 100%;
        padding: 16px;
    }
}