.tableRowHover {
    .ecoInitoatives-category-container {
        width: 16px;
        transition: width 0.5s;
        padding-left: 16px;
        .ecoInitoatives-category-img {
            height: 16px;
            margin-bottom: 14px;
        }
    }
    &:hover {
        .row-hover {
            background-color: rgba(55, 135, 201, 0.0705882353) !important;
        }
        .actionContainer {
            display: flex;
            visibility: visible;
            border-radius: 4px 0px 0px 4px;
            background-color: #e2f1fe;
        }
        .ecoInitoatives-category-container {
            background-color: rgba(55, 135, 201, 0.0705882353) !important;
            // width: 96px;
            // margin-left: 0px;
            // .ecoInitoatives-category-img {
            //     height: 45px;
            //     margin-bottom: 0px;
            // }
        }
    }
    .actionContainer {
        background-color: #D8DEE2;
        width: 72px;
        height: 72px;
        position: absolute;
        right: 0;
        bottom: 0;
        visibility: hidden;
        transition: visibility 0s, background-color 0.5s linear;
    }
}

.border-type {
    border-left: 4px solid #FF9B76;
    border-radius: 4px 0px 0px 4px;
}