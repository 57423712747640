.container {
    min-height: 100vh;
    position: relative;
    background: #F2F9FF;

    .logo {
        width: 75px;
        height: 38px;
    }

    .icon {
        width: 17px;
        height: 19px;
        padding: 0px 8px;
    }

    .logoImg {
        padding: 48px 40px 48px 40px;
    }
}