.verifyMobileFormContainer {
    width: 381px;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    img {
        width: 30px;
        height: 20px;
    }
}

.css-1504u2j-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.26) !important;
}