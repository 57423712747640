.paymentContainer {
    .showDuePayment {
        background-color: #FFE9E8;
    }

    .dueDataContainer {
        border-radius: 8px;
        height: 16px;
        width: 96px;
        margin-left: 12px;
    }
}

.paymentCard {
    background-color: #FFF;
    border-radius: 4px;
    box-shadow: 0px 1px 2px #00000029;
    min-height: 127px;
    padding: 14px 16px 16px 16px;
    margin-top: 16px;
}