.organizationProfile {
    // .MuiInputBase-root {
    //     background-color: transparent !important;
    //     border-color: #496073;
    // }
}

.fixed-title {
    position: fixed;
    z-index: 1;
    background-color: #F2F9FF;
    width: 100%;
    margin-left: -16px;
    margin-top: -22px;
    padding-left: 16px;
    padding-top: 16px;
}

@media only screen and (max-width: 1000px) {
    .fixed-title {
        padding-top: 19px;
    }
}