.ecoInitoativesContainer {
    .ecoInitoativesContainerBody {
        overflow-y: auto;
        height: calc(100vh - 122px);
        .ecoTemplate-container {
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            padding-left: 16px;
            max-width: calc(100vw - 72px);
        }
    }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}