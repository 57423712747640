.cancelBtn {
    border-color: #496073 !important;
    color: #061D30 !important;
    height: 40px;
    min-width: 104px !important;
}

.successBtn {
    color: #fff;
    height: 40px;
    min-width: 104px !important;
}