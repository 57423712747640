.eco-mobile-footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    z-index: 1;
    background-color: #E2F2FF;
    .diZ-tab {
        opacity: 0.5;
        cursor: pointer;
        z-index: 2;
        &.activeTab {
            color: #fff;
            opacity: 1;
        }
    }
    .glider {
        position: absolute;
        display: flex;
        width: 50%;
        height: 56px;
        background-color: #3787C9;
        z-index: 1;
        transition: 0.25s ease-out;
    }
}