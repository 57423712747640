.CampaignTable {
    .tableRowHover {
        .showOnHoverAction {
            visibility: hidden;
            position: absolute;
            right: 0;
            top: 0;
            height: 48px;
            background: #F2F9FF;
            border-radius: 4px 0px 0px 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-content: space-around;
            min-width: 144px;

            .MuiIconButton-root {
                padding: 5px 5px !important;
            }
        }
    }

    .tableRowHover:hover {
        background-color: #3787C912;

        .showOnHoverAction {
            visibility: visible;
        }
    }

    .selectedStyle::before {
        content: "";
        background: #106AA5;
        position: absolute;
        left: 0;
        height: 100%;
        width: 4px;
        top: 0;
    }

    .tablecellonHover {
        position: relative;

        // .checkboxonHover {
        //     visibility: hidden;
        // }

        .indexStyle {
            visibility: visible;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 48px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // .tablecellonHover:hover {
    //     .checkboxonHover {
    //         visibility: hidden;
    //     }

    //     .indexStyle {
    //         visibility: visible;
    //     }
    // }

    .indexChecked {
        display: none;
    }
}

.campaignMobileCard {
    background-color: #FFF;
    border-radius: 4px;
    padding: 14px 8px;
    min-height: 196px;
    margin-bottom: 8px;

    .boxContainer {
        border-radius: 4px;
        background-color: #E2F2FF;
        padding: 6px 8px;
        min-height: 100px;
    }
}

.campaignSect {
    .optionsSelectionStyle {
        display: flex;
        align-items: center;
        margin-left: 13px;
        // background: #E4E4E4;
        border-radius: 16px;
        width: 176px;
        height: 32px;
        cursor: pointer;
    }
}