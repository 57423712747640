.signUpContainer {
    min-height: 100vh;
    position: relative;
    background: #F5FAFD;


    .leftStack {
        position: relative;
        padding-left: 140px;

        .logoImg {
            // margin-top: 80px;
            margin-top: 10.416666666667vh;
        }

        .detailContainer {
            width: 558px;
            height: 503.98px;
            // margin-top: 95.92px;
            margin-top: 11.44921875vh;

            .detailsList {
                row-gap: 54.4px;

                .details {
                    margin-left: 31.78px;
                }
            }
        }

        .socialContainer {
            // column-gap: 24px;
            // margin-top: 184.23px;
            margin-top: 23.98828125vh;
        }
    }

    .rightStack {
        .innerContainer {
            margin-top: 10.916667vh;
            width: 328px;
        }
    }

    .dizcopyrights {
        position: absolute;
        bottom: 18px;
        left: 0;
        right: 0;
        text-align: center;
    }

    .formSubmittedStyle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 328px;
        height: 100vh;
    }

    .mobileViewContainer {
        .logoImg {
            padding-top: 15px;

            img {
                width: 165px;
                height: 41px;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 16px;
        width: calc(100% - 32px);

        .dizcopyrights {
            position: initial;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: initial;
        }

        .socialContainer {
            // column-gap: 24px;
        }
    }
}