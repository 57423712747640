.mobileModeSettingConatiner {
    background-color: #F2F9FF;
    padding: 64px 16px 100px;
    box-sizing: border-box;
    min-height: 100vh;
}

.mobileModeSettingFooter {
    height: 64px;
    background-color: #ECEFF1;
    padding: 0 24px;
    color: #061D30;
    position: sticky;
    bottom: 0;
    width: calc(100% - 48px);

    .activeFooterMenu {
        height: 40px;
        background-color: #3787C9;
        border-radius: 20px;
        color: #FFFFFF;
        padding: 0 16px;
    }
}