.analyticChartCont {
    .optionsSelectionStyle {
        display: flex;
        align-items: center;
        // margin-left: 13px;
        // background: #E4E4E4;
        border-radius: 16px;
        width: 100%;
        height: 32px;
        cursor: pointer;
    }
}