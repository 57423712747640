.analyticsCard {
    background-color: #FFF;
    border: 1px solid #7070704D;
    border-radius: 4px;
    width: 128px;
    height: 88px;
    margin-right: 33px;
    padding: 16px 8px 16px 16px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.mobileAnalyticsCard {
    border: 1px solid #7070704D;
    background: #FFF;
    border-radius: 4px;
    min-height: 37px;
    padding: 16px 16px 11px 16px;
    margin-bottom: 8px;
}