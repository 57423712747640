.IntegrationContainer {

    .hoverBlock:hover {
        .MuiSvgIcon-root {
            color: #A61B30 !important;
        }
    }

    .hoverCell {
        .hideonHover {
            display: block;
        }

        .showonHover {
            display: none;
        }
    }

    .hoverCell:hover {
        .hideonHover {
            display: none;
        }

        .showonHover {
            display: flex;
        }
    }

    .tableRowHover:hover {
        background-color: #3787C912 !important;
    }

    .createApiKeyNoData {
        height: calc(100vh - 184.13px);
        display: flex;
        justify-content: center;
        align-items: center;

        .innerNoData {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            // position: relative;
        }
    }

    .mobileaddstyle {
        width: 56px;
        height: 56px;
        background: #8AC865 !important;
        color: #FFF;
        box-shadow: 0px 3px 6px #00000029 !important;
        // position: initial !important;
        // bottom: 0;
        // right: 0;
    }

    .bottomAction {
        position: fixed !important;
        bottom: 24px;
        right: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .btnStyle {
            width: 184px;
            height: 56px;
            border-radius: 28px;
            text-transform: none;
            font-weight: 500 !important;
            margin-bottom: 24px;
            box-shadow: 0px 3px 6px #00000029 !important;
        }
    }
}

.integrationCard {
    background-color: #FFF;
    border-radius: 4px;
    box-shadow: 0px 1px 2px #00000029;
    min-height: 80px;
    padding: 16px 12px 16px 16px;
    margin-top: 16px;
}