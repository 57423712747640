.AnalyticsheaderContainer {

    padding: 8px 16px;
    // position: sticky;
    top: 0;
    z-index: 1;
    background: #F7F7F7;

    .optionsSelectionStyle {
        display: flex;
        align-items: center;
        margin-left: 13px;
        // background: #E4E4E4;
        border-radius: 16px;
        width: 176px;
        height: 32px;
        cursor: pointer;
    }

    @media only screen and (max-width: 1000px) {
        margin-top: 56px;

        .optionsSelectionStyle {
            display: flex;
            align-items: center;
            margin-left: 13px;
            background: #E4E4E4;
            border-radius: 16px;
            width: 88px;
            height: 32px;
            cursor: pointer;
        }
    }


}