h1 {
    color: #3689c9;
}

.ff-Roboto {
    font-family: "Roboto" !important;
}

// .ff-OpenSans {
//     font-family: "Open Sans" !important;
// }
// .ff-NotoSans {
//     font-family: "Noto Sans" !important;
// }
.fw-thin {
    font-weight: 100 !important;
}

.fw-light {
    font-weight: 300 !important;
}

.fw-regular {
    font-weight: 400 !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-semibold {
    font-weight: 600 !important;
}

.fw-bold {
    font-weight: bold !important;
}

.fs-7 {
    font-size: 7px !important;
}

.fs-14 {
    // font-size: 14px !important;
    font-size: 0.875rem !important;
    /* 14px */
    line-height: 1.25rem !important;
}

.fs-12 {
    // font-size: 12px !important;
    font-size: 0.75rem !important;
    /* 12px */
    line-height: 1rem !important;
    /* 16px */
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-20 {
    // font-size: 20px !important;
    font-size: 1.25rem !important;
    /* 20px */
    line-height: 1.75rem !important;
    /* 28px */
}

.fs-32 {
    // font-size: 32px !important;
    font-size: 2rem !important;
    /* 32px */
    line-height: 2.25rem !important;
    /* 36px */
}

.text-upper {
    text-transform: uppercase;
}

.mt-6 {
    margin-top: 6px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mt-9 {
    margin-top: 9px !important;
}

.mt-14 {
    margin-top: 14px !important;
}

.mt-13 {
    margin-top: 13px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-17 {
    margin-top: 17px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-22 {
    margin-top: 22px !important;
}

.mr-24 {
    margin-right: 24px !important;
}

.pa-16 {
    padding: 16px;
}

.horizontal-padding {
    padding-left: 16px;
    padding-right: 16px;
}

.innerPadding {
    padding: 8px 16px 16px 16px;
    background-color: #f7f7f7;
    height: calc(100vh - 80px);
}

.cursor-pointer {
    cursor: pointer;
}

.svgIcons {
    transform: scale(0.8) !important;
    color: #8ab756 !important;
}

.svgTransform {
    transform: scale(0.889) !important;
}

.svgColor {
    color: #99ac8b !important;
}

.MuiOutlinedInput-input {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: "Roboto" !important;
}

.black {
    color: #000000;
}

.flex {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    -webkit-box-direction: column;
    -moz-box-direction: column;
    -ms-flex-direction: row;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
}

.flex-aligncenter {
    display: flex;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    align-items: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
}

.flex-justifycenter {
    display: flex;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
}

.flex-spaceBetween {
    display: flex;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    align-items: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: space-between;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
}

.flex-center {
    display: flex;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
}

.main-Container {
    background-color: #f1f1f1;
    height: 100vh;
}

.capitalize {
    text-transform: capitalize;
}

.ecoInitoativesContainer {
    .tablepagination {
        .textFieldSelect {
            .MuiFormControl-root {
                height: 29.13px;
                width: 48px;
                border-radius: 0;
                border: none;
                outline: none;
                background: transparent;
            }

            .MuiInputBase-root {
                border-radius: 0 !important;
                border: none;
                outline: none;
                background: transparent;
            }

            .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
                display: flex;
                align-items: center;
                padding: 0 5px 0 10px !important;
                outline: none;
                border: none;
                font-size: 14px !important;
                font-family: "Noto Sans" !important;
                font-weight: bold !important;
                color: #474747;
            }

            .MuiSvgIcon-root {
                transform: scale(0.778) !important;
                color: #c3c3c3 !important;
            }

            .MuiOutlinedInput-notchedOutline {
                outline: none;
                border: none;
                appearance: none;
            }
        }
    }

    .MuiTablePagination-root {
        .MuiToolbar-root {
            height: inherit !important;
            min-height: inherit !important;
            border: 1px solid #c3c3c3 !important;
            padding: 0 0px !important;
            border-radius: 4px;

            .MuiTablePagination-selectLabel {
                display: none;
            }

            .MuiInputBase-root {
                margin: 0 5px 0 0 !important;
            }

            .MuiTablePagination-displayedRows {
                display: none;
                margin: 0 !important;
            }

            .MuiBox-root {
                align-items: center;
                display: flex;
                padding: 8px 8px 8px 0 !important;
                margin-left: 10px !important;

                .MuiButtonBase-root {
                    padding: 0 !important;

                    .MuiSvgIcon-root {
                        transform: scale(1.2) !important;
                    }
                }
            }
        }
    }
}

.inputFormStyle {
    .MuiFormControl-root {
        height: 32px;
        width: 152px;
        background: white;
        border-radius: 20px;
        border-color: #d8d8d8;
        margin-right: 16px;

        .MuiInputBase-root {
            height: 100%;
            width: 100%;
            border-radius: 20px;

            .MuiInputBase-input {
                height: 100%;
                width: 100%;
                font-size: 12px !important;
                color: #565656;
                padding: 8px 14px !important;
            }
        }
    }

    .tablepagination {
        .textFieldSelect {
            .MuiFormControl-root {
                height: 29.13px;
                width: 48px;
                border-radius: 0;
                border: none;
                outline: none;
                background: transparent;
            }

            .MuiInputBase-root {
                border-radius: 0 !important;
                border: none;
                outline: none;
                background: transparent;
            }

            .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
                display: flex;
                align-items: center;
                padding: 0 5px 0 10px !important;
                outline: none;
                border: none;
                font-size: 14px !important;
                font-family: "Roboto" !important;
                font-weight: bold !important;
                color: #474747;
            }

            .MuiSvgIcon-root {
                transform: scale(0.778) !important;
                color: #c3c3c3 !important;
            }

            .MuiOutlinedInput-notchedOutline {
                outline: none;
                border: none;
                appearance: none;
            }
        }
    }

    .MuiTablePagination-root {
        .MuiToolbar-root {
            height: 32px !important;
            min-height: 32px !important;
            border: 1px solid #c3c3c3 !important;
            padding: 0 0px !important;
            border-radius: 4px;
            overflow: hidden;

            .MuiTablePagination-selectLabel {
                display: none;
            }

            .MuiInputBase-root {
                margin: 0 5px 0 0 !important;
            }

            .MuiTablePagination-displayedRows {
                display: none;
                margin: 0 !important;
            }

            .MuiBox-root {
                align-items: center;
                display: flex;
                padding: 8px 8px 8px 0 !important;
                margin-left: 10px !important;

                .MuiButtonBase-root {
                    padding: 0 !important;

                    .MuiSvgIcon-root {
                        transform: scale(1.2) !important;
                    }
                }
            }
        }
    }
}

.mainUserTableHead {
    border-radius: 4px 4px 0px 0px;

    // height: 48px !important;
    .MuiTableRow-root {
        height: 48px !important;
    }

    .MuiSvgIcon-root {
        transform: scale(0.889) !important;
    }

    .MuiTableCell-root {
        padding: 0;
    }

    .MuiCheckbox-root {
        padding: 9px 11px !important;
    }
}

.mainUserBodyTable {
    background: #fff;

    .MuiTableCell-root {
        padding: 0;
    }

    .MuiTableRow-root {
        height: 48px !important;
    }

    .tablecellonHover {
        .MuiSvgIcon-root {
            transform: scale(0.889) !important;
        }
    }
}

.datepickerSetting {
    .endDatePicker {
        .MuiInputBase-root {
            height: 100%;
            justify-content: end;
        }

        .MuiInputBase-input {
            position: absolute;
            width: 193px;
            left: 0;
            min-height: initial !important;
            padding-right: 32px !important;
        }
    }
}

.editContainer {

    // .MuiSelect-select {
    //   position: absolute;
    //   left: 0;
    //   width: 278px;
    //   padding-left: 44px;
    //   min-height: initial !important;
    //   padding-right: 32px !important;
    //   text-overflow: ellipsis !important;
    //   white-space: nowrap !important;
    //   overflow: hidden !important;
    //   height: 23px !important;
    // }
    .MuiInputBase-root {
        height: 56px !important;
    }

    .multilineField {
        .MuiInputBase-root {
            height: initial !important;
            background: rgb(73, 96, 115, 0.11) !important;
        }

        .MuiOutlinedInput-input {
            line-height: 21px !important;
        }
    }
}

.addContainer {
    .MuiSelect-select {
        padding-right: 6px !important;
    }
}

.disabled {
    background-color: #49607333 !important;
    color: #061d30;
}

.MuiInputBase-root {
    &.Mui-disabled {
        background-color: #49607333 !important;
        color: #061d30 !important;
        -webkit-text-fill-color: #061d30 !important;

        input {
            -webkit-text-fill-color: #061d30 !important;
        }
    }
}

.disabled-input {
    background-color: rgba($color: #496073, $alpha: 0.15) !important;
    color: #061d30 !important;
    -webkit-text-fill-color: #061d30 !important;
}

@media only screen and (max-width: 1000px) {
    .userManagementContainer {
        .MuiFormControl-root {
            margin-right: 0;
        }
    }

    .mobileviewStyle {
        display: none !important;
    }

    .innerPadding {
        height: auto;
        min-height: 100vh;
        padding: 60px 16px 16px 16px;
    }
}

.Toastify__toast-container--bottom-center {
    bottom: 0 !important;
    margin-bottom: 8px !important;
}

.Toastify__toast {
    border-radius: 20px 20px 0 0 !important;
    margin-bottom: 0 !important;
    box-shadow: 0px -5px 6px #00000029 !important;
}

.Toastify__toast-body {
    font-size: 14px;
    color: #061d30;
    font-family: "Roboto";
    font-weight: 500;
    // text-transform
}

.Toastify--animate-icon {
    display: none !important;
}

.Toastify__close-button--light {
    position: absolute;
    top: 24px;
    right: 10px;
    transform: scale(1.53);
    opacity: 1 !important;
}

.Toastify__progress-bar {
    background-color: #106aa5 !important;
}

.ellipseStyle {
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.twoEllipseStyle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.wordBreak {
    word-break: break-word !important;
}

.MuiDialog-container {
    .MuiPaper-root {
        overflow-x: hidden !important;
    }
}

//new css for auth
.checkboxStyle {
    .MuiButtonBase-root {
        padding: 0 9px !important;
        height: 16px !important;

        .MuiSvgIcon-root {
            transform: scale(0.889) !important;
        }
    }
}

.MuiFormHelperText-root {
    margin-left: 0 !important;
    line-height: initial !important;
    color: #C71212 !important;
}

.MuiInputBase-root {
    background: #fff;
}

.opacity-5 {
    opacity: 0.5;
}

.color-inherit {
    color: inherit !important;
}

.css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel {
    background: #3787c9 !important;
    border-radius: 4px !important;
    color: #ffffff !important;
    height: 32px !important;
    width: 32px !important;
}

.css-qklzlb-MuiDayPicker-header {
    padding: 0 23px;
    justify-content: space-between !important;
}

.noData {
    background: #fff;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reportBar {
    .MuiFormControl-root {
        width: 100%;
    }

    .MuiFormControlLabel-root {
        width: 100%;
        height: 100%;
        padding-right: 16px;
        margin-right: 0;
    }

    .MuiFormControlLabel-label {
        font-size: 12px;
        font-family: "Roboto";
        font-weight: 400;
        margin-left: 10px;
    }

    .MuiButtonBase-root {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 32px !important;
        min-height: 32px !important;
    }
}

.err-msg {
    margin-left: 0 !important;
    line-height: initial !important;
    color: #061d30 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    letter-spacing: 0.03333em !important;
    text-align: left !important;
    margin-top: 3px !important;
    margin-right: 14pxv;
    margin-bottom: 0 !important;
}

.popup-menu {
    .MuiMenu-list {
        padding-top: 0 !important;
    }
}

.filter {
    .MuiMenuItem-root {
        font-size: 12px;
        font-weight: 500;
    }

    .Mui-disabled {
        background: rgba($color: #E2F2FF, $alpha: 0.5);
        opacity: 1 !important;
        color: #106AA5 !important;
    }
}

.MuiButtonBase-root {
    box-shadow: none !important;
}

.diz-dropdown {
    box-sizing: border-box;
    width: 176px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #707070;
    padding: 7px 16px;

    .dropdown-label {
        color: #000;
    }

    .dropdown-icon {
        color: rgba($color: #000000, $alpha: 0.3);
    }

    &:hover {
        border-color: #fff;
    }

    &.dd-opened {
        background-color: #E2F2FF;
        border-color: #E2F2FF;

        .dropdown-label {
            color: #106AA5 !important;
            font-weight: 600 !important;
        }

        .dropdown-icon {
            color: rgba($color: #3787C9, $alpha: 0.3) !important;
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.g-recaptcha {
    transform: scale(0.85);
    transform-origin: 0 0;
}