.drop-zone-container {
    position: relative;
    box-sizing: border-box;
    .cancelButton {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 30px;
        height: 30px;
    }
    .imgContainer {
    }
    .dropZone {
        width: 100%;
        height: 100%;
        padding: 16px;
    }
}