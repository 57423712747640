
.actnowFormContainer {
    width: 381px;
    min-height: 500px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 48px 24px 48px 24px;
    border-width: 1px;
    border-color: #E3F2FF;
    box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 2px 2px 17px 0px rgba(0, 0, 0, 0.15);
}