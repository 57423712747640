.campaignCardContainer {
    height: 200px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin-top: 16px;
    padding: 18px 16px 16px;
    box-sizing: border-box;
    border-left: 4px solid;
    .categoryImg {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
}