// .loginComponent {
//     min-height: 100vh;
//     position: relative;

//     .logoImg {
//         position: absolute;
//         top: 104px;
//         left: 112px;
//     }

//     .leftBottomImg {
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         z-index: -999;
//     }

//     .rightBottomImg {
//         position: absolute;
//         right: 0;
//         bottom: 0;
//         z-index: -999;
//     }

//     .registerForm {
//         width: 328px;
//         margin: auto;
//         padding-top: 96px;
//     }

//     .innerDiv {
//         height: 100%;
//         width: 100%;
//     }

//     .mainContainer {
//         max-width: 1500px;
//         margin: auto;
//         position: relative;
//         min-height: 100vh;
//     }

//     .createHeading {
//         margin-bottom: 25px;
//     }

//     .brandLogoContainer {
//         text-align: center;
//         margin-bottom: 51px;
//     }

//     .dizcopyrights {
//         position: absolute;
//         bottom: 18px;
//         left: 0;
//         right: 0;
//         text-align: center;
//     }

//     @media only screen and (max-width: 1000px) {
//         .logoImg {
//             position: absolute;
//             top: 3%;
//             left: 8%;
//         }

//         .mobileLogo {
//             height: 41px;

//             img {
//                 width: 72px;
//                 height: 37.34px;
//             }
//         }

//         .innerDiv {
//             margin-top: 48px;
//         }

//         .leftBottomImg {
//             display: none;
//         }

//         .rightBottomImg {
//             img {
//                 width: 230px;
//             }
//         }
//     }
// }

.loginContainer {
    min-height: 100vh;
    position: relative;
    background: #F5FAFD;

    .leftStack {
        position: relative;
        padding-left: 140px;

        .logoImg {
            // margin-top: 80px;
            margin-top: 10.416666666667vh;
        }

        .detailContainer {
            width: 558px;
            height: 503.98px;
            // margin-top: 95.92px;
            margin-top: 11.44921875vh;

            .detailsList {
                row-gap: 54.4px;

                .details {
                    margin-left: 31.78px;
                }
            }
        }

        .socialContainer {
            // column-gap: 24px;
            // margin-top: 184.23px;
            margin-top: 23.98828125vh;
        }
    }

    .formSubmittedStyle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 328px;
        height: 100vh;
    }

    .dizcopyrights {
        // position: absolute;
        // bottom: 18px;
        // left: 0;
        // right: 0;
        text-align: center;
    }

    .mobileViewContainer {
        .logoImg {
            padding-top: 15px;

            img {
                width: 165px;
                height: 41px;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 16px;
        width: calc(100% - 32px);
        align-items: center;

        .dizcopyrights {
            position: initial;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: initial;
        }

        .socialContainer {
            // column-gap: 24px;
        }
    }

    .socialContainer {
        // column-gap: 24px;
    }

    .footerWeb {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 18px;
        width: 328px;
    }
}