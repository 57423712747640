.ReportTable {
    .tableRowHover:hover {
        background-color: #3787C912;
    }
}

.reportMobileCard {
    background-color: #FFF;
    margin-bottom: 8px;
    border-radius: 4px;
    padding: 16px 16px 8px;
    min-height: 40px;
    border: 1px solid #7070704D;
}