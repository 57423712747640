.desktopModeSettingConatiner {
    max-height: calc(100vh - 88px);
    height: calc(100vh - 88px);
    margin: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    .sideMenu {
        background-color: #4960731A;
        padding: 22px 24px 16px;
        .sidenavBtn {
            width: '208px';
            height: '48px';
            justify-content: flex-start;
        }
    }
    .sideMenuContent {
        background-color: #F2F9FF;
        padding: 22px 46px 16px;
        overflow: auto;
        position: relative;
    }
    .MuiButton-containedPrimary {
        background-color: #3787C9 !important;
    }
    .MuiButton-nonePrimary {
        color: #061D30;
    }
}