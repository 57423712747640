.sideToggleBtn {
    padding: 10px 16px;
    max-height: 40px;
    max-width: 144px;
    background-color: #fff;
    margin-bottom: 8px;
    border-radius: 20px;
    cursor: pointer;
    .arrow {
        opacity: 0;
    }
    &:hover {
        background-color: rgba(73, 96, 115, 0.05);
        .arrow {
            opacity: 0.5;
        }
    }
    &.btn-active {
        background-color: rgba(73, 96, 115, 0.3);
        .arrow {
            opacity: 1;
        }
    }
}

.sideBodyContainer {
    // max-height: 580px;
    min-height: 575px;
    overflow-y: auto;
    // padding-right: 16px;
}

.sideBodyContainerMobile {
    // max-height: 544px;
    // min-height: 580px;
    overflow-y: auto;
    // padding-right: 16px;
}